import { LookerEmbedDashboard } from "@looker/embed-sdk/lib/dashboard_client";
import { LookerEmbedExplore } from "@looker/embed-sdk/lib/explore_client";
import { LookerEmbedLook } from "@looker/embed-sdk/lib/look_client";
import { EmbedBuilder } from "./session";
export class EmbedHandler {
    constructor(sdk) {
        this.settings = {
            apiHost: sdk.urls.lookerHost
        };
        // URL will be used to obtain a signed URL for embedding dashboards
        this.settings.auth = {
            url: `${sdk.urls.burnsBaseUrl}/looker/embed/auth`
        };
    }
    /**
     * @param id: the ID of the dashboard to embed.
     * @return: a fluent builder which can embed the iframe. If it is not told explicitly where to append the iframe, it appends it to document.body.
     */
    dashboard(id) {
        return (new EmbedBuilder(this.settings, "dashboard", "/embed/dashboards", LookerEmbedDashboard)
            .withId(id)
            // these enforced .with<Thing>'s here basically ensure that the dashboard properly fills out its height, and doesn't scroll oddly
            .withDynamicIFrameHeight()
            .withDialogScroll()
            .withScrollMonitor());
    }
    explore(id) {
        return new EmbedBuilder(this.settings, "explore", "/embed/explore", LookerEmbedExplore).withId(id);
    }
    look(id) {
        return new EmbedBuilder(this.settings, "look", "/embed/looks", LookerEmbedLook).withId(id);
    }
}
