import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
const observers = {};
/**
 * A wrapper for useState that will maintain the state for other components.
 * @param key A unique key to identify the state.
 * @returns The created state (same as useState) that will be maintained by key.
 */
export const useMadState = (key, value) => {
    const [data, setDataState] = useState(typeof value !== "undefined" ? value : undefined);
    let observer = observers[key];
    let subscription;
    if (!observer) {
        observers[key] = new BehaviorSubject(typeof data !== "undefined" ? data : undefined);
        observer = observers[key];
    }
    useEffect(() => {
        subscription = observer.subscribe(setDataState);
        return () => {
            subscription.unsubscribe();
            // Becuase `useEffect` is deferred, this cleanup logic can happen while a subscription is imminent.
            // `setTimeout` defers the logic until after the `useEffect`
            setTimeout(() => {
                if (!observer.observed) {
                    delete observers[key];
                }
            });
        };
    }, []);
    return [data, (d) => observer.next(typeof d === "function" ? d(data) : d)];
};
