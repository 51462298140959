import {
  cloneElement,
  FC,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef
} from "react";
import { useSearcher } from "../../../hooks";
import {
  ExternalSearchPropsOnTable,
  LocalSearchPropsOnTable,
  TableHeadingProps,
  TableProps,
  TableRowType,
  TableSearchInputProps
} from "..";
import { Input } from "../..";
import { TableRowActionDef } from "../types";
import { DoNotCare, KeyPaths } from "../../types";
import { WrappedTableComponent } from "./types";
import {
  deepLookupElementsByDisplayName,
  lookupElementsByDisplayName
} from "../../utils";
import { hasValue, toArray } from "../../../utils";
import { TableHeading } from "../Heading";

/**
 * Finds Table.Search component by looking in:
 * 1. `Table.children`
 * 2. `Table.Heading.actions` found in `Table.children`
 */
const lookupSearchComponentInTable = (
  children: DoNotCare
): undefined | ReactElement<TableSearchInputProps> =>
  deepLookupElementsByDisplayName("Table.Search", children, {
    parentNames: new Set(["Table.Heading"]),
    lookIn: "actions"
  })[0];

/**
 * Lookup function for deriving search props from:
 * 1. Table's `search` prop
 *    - if found, used as base for response object
 * 2. `Table.Search` component in children
 *    - if found, adds its props to the response object
 * @param tableProps - props on the Table used to lookup search props
 */
const lookupTableSearchProps = (tableProps: TableProps<DoNotCare>) => {
  const { children, search } = tableProps;
  const { props } = lookupSearchComponentInTable(children) || {};
  if (!search && !props) {
    return undefined;
  }
  return {
    ...(search || {}),
    ...(props || {})
  } as LocalSearchPropsOnTable<DoNotCare> | ExternalSearchPropsOnTable;
};

/** Type guard for Table.Heading */
const isTableHeadingElement = (
  elem: DoNotCare
): elem is ReactElement<TableHeadingProps> =>
  elem?.props && elem?.type?.displayName === "Table.Heading";

/** The Table's search input element */
const TableSearchInput: FC<TableSearchInputProps> = memo(
  ({
    placeholder = "search...",
    onChange,
    onBlur,
    onFocus,
    value,
    disabled
  }) => (
    <Input
      type="text"
      prefix="Search"
      value={value}
      placeholder={placeholder}
      onChange={value => onChange?.(value)}
      onBlur={onBlur}
      disabled={disabled}
      onFocus={onFocus}
    />
  )
);

/**
 * Higher Order Component that renders search input on the table.
 * It determines the placement of the input, by looking in Table.children and Table.Heading.actions.
 * - If Table.Search component is not found, the input is prepended to the first Table.Heading.actions
 *   - If there are not Table.Heading elements on the Table, one is added to include the search input.
 */
function withSearchInput<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * The Table that includes the search input in the desired Table.Heading on the Table
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const inputProps = lookupTableSearchProps(props) as TableSearchInputProps;
    const { children } = props;
    const foundSearchElem = lookupSearchComponentInTable(children);

    const isSearchInputPlaced = useRef(false);
    // we want to set to false on every render
    isSearchInputPlaced.current = false;

    if (!foundSearchElem && !props?.search) {
      return <WrappedTable {...props} />;
    }
    const childrenArr = toArray(children).filter(child => hasValue(child));

    const searchInput = (
      <TableSearchInput key="kit-table-search-input" {...inputProps} />
    );

    let [firstHeadingElem] = lookupElementsByDisplayName(
      "Table.Heading",
      children
    );

    if (!firstHeadingElem) {
      firstHeadingElem = (
        <TableHeading key="kit-table-search-heading" actions={[searchInput]} />
      );
      childrenArr.push(firstHeadingElem);
      isSearchInputPlaced.current = true;
    }

    return (
      <WrappedTable {...props}>
        {childrenArr.map(child => {
          if (isSearchInputPlaced.current || !isTableHeadingElement(child)) {
            return child!;
          }

          const [foundSearchInThisHeading] = lookupElementsByDisplayName(
            "Table.Search",
            child.props.actions
          );

          if (!foundSearchInThisHeading && foundSearchElem) {
            return child!;
          }

          const { actions = [] } = child.props || {};
          const actionsArr = toArray(actions).filter(Boolean);
          const actionIndex = foundSearchElem
            ? actionsArr.findIndex(action => action === foundSearchElem)
            : -1;

          if (actionIndex === -1) {
            actionsArr.unshift(searchInput);
          } else {
            actionsArr[actionIndex] = searchInput;
          }

          isSearchInputPlaced.current = true;

          return cloneElement(child, {
            ...child.props,
            key: child.key,
            actions: actionsArr
          });
        })}
      </WrappedTable>
    );
  };
}

/**
 * Higher Order Component that adds local search functionality to a table component.
 * Manages filtering data locally via the `useSearcher` hook.
 */
function tableWithLocalSearch<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * The Table that handles local search state.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const { data, search } = props;

    const searchProps = useMemo(
      () => lookupTableSearchProps(props),
      [props?.children, props?.search]
    );

    const normalizedKeys = useMemo(() => {
      if (!searchProps) {
        return [];
      }
      const { keys } = searchProps;
      if (Array.isArray(keys)) {
        return keys.map(String);
      }
      return String(keys);
    }, [searchProps?.keys]);

    const { filteredItems, query, setQuery, isLoading } = useSearcher({
      items: data || [],
      keys: normalizedKeys,
      disabled: searchProps?.disabled
    });

    useEffect(() => {
      // updating search query when incoming value changes
      if (search?.value && search.value !== query) {
        setQuery(search.value);
      }
    }, [search?.value]);

    const onChange = (value: string) => {
      setQuery(value);
      searchProps?.onChange?.(value);
    };

    return (
      <WrappedTable
        {...props}
        data={filteredItems}
        isLoading={props?.isLoading || isLoading}
        search={{
          ...searchProps,
          keys: normalizedKeys as KeyPaths<Row>,
          value: query,
          disabled: searchProps?.disabled,
          onChange
        }}
      />
    );
  };
}

/**
 * Determines if Table should handle external search — based on props
 */
export const isTablePropsWithExternalSearch = (
  props: TableProps<DoNotCare>
) => {
  const searchProps = lookupTableSearchProps(props);
  return (
    !!searchProps &&
    !searchProps.keys &&
    "value" in searchProps &&
    typeof searchProps.onChange === "function"
  );
};

/**
 * Determines if Table should handle local search — based on props
 */
export const isTablePropsWithLocalSearch = (props: TableProps<DoNotCare>) => {
  const searchProps = lookupTableSearchProps(props);
  return (
    !!searchProps &&
    "keys" in searchProps &&
    (typeof searchProps.keys === "string" || Array.isArray(searchProps.keys))
  );
};

/**
 * Higher Order Component that adds search functionality to a table component.
 * @param WrappedTable The kit Table component to wrap.
 * @returns A Table component that can handle search, with the initial + additional props
 */
export function tableWithSearch<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: FC<TableProps<Row, Column, Action>>) {
  /**
   * Table that handles local or external search.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const Table = useMemo(() => {
      if (
        !isTablePropsWithLocalSearch(props) &&
        !isTablePropsWithExternalSearch(props)
      ) {
        return WrappedTable;
      }

      let Table = withSearchInput(WrappedTable);

      if (isTablePropsWithLocalSearch(props)) {
        Table = tableWithLocalSearch<Row, Column, Action>(Table);
      }

      return Table;
    }, [
      isTablePropsWithLocalSearch(props),
      isTablePropsWithExternalSearch(props)
    ]);

    return <Table {...props} />;
  };
}
