import { NotImplementedError } from "../../../../errors";
import { parsePacingResponse } from "../../../../models/looker/modules/pacing";
import { DateTime } from "luxon";
import { Handler } from "../../../handlers";
import { PACING_QUERY } from "./query";
class Pacing extends Handler {
    constructor(sdk) {
        super(sdk, "looker-pacing");
    }
    async findItems(filters) {
        const dateFilter = filters.where?.find((filter) => filter.field === "dates")?.value;
        if (!dateFilter) {
            throw new Error("Dates filter is required");
        }
        if (!Array.isArray(dateFilter)) {
            throw new Error("Dates filter must be an array");
        }
        const dateRange = [];
        for (const date of dateFilter) {
            if (date instanceof Date) {
                dateRange.push(date);
            }
        }
        if (dateRange.length !== 2) {
            throw new Error("Dates filter must contain two dates");
        }
        const queryBody = {
            ...PACING_QUERY,
            filters: this.filters(dateRange)
        };
        const hashKey = await this.sdk.looker.query.hashQuery(queryBody);
        return this.cache.promise(hashKey, () => this.sdk.looker.query
            .run(queryBody)
            .then(({ data, queryMeta }) => [
            {
                data: parsePacingResponse(data),
                queryMeta
            }
        ])
            .catch(() => {
            throw new Error("Failed to fetch pacing data");
        }));
    }
    filters(dates) {
        return {
            ...PACING_QUERY.filters,
            "vw_dim_lineitem.flt_analysis_interval": dates
                .map((d) => DateTime.fromJSDate(d).toFormat("yyyy/MM/dd"))
                .join(" to ")
        };
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Pacing;
