import { ReactNode, forwardRef, useCallback } from "react";
import {
  Intent,
  IRef,
  MaybeElement,
  MenuItem as BlueprintMenuItem,
  MenuProps,
  IconSize,
  Icon,
  IPopoverProps
} from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import "./item.scss";

interface Props {
  /** blueprinjs props */
  text: ReactNode;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  elementRef?: IRef<HTMLLIElement>;
  href?: string;
  htmlTitle?: string;
  icon?: IconName | MaybeElement;
  intent?: Intent;
  label?: string;
  labelClassName?: string;
  labelElement?: ReactNode;
  multiline?: boolean; // default: false
  onClick?: (event: any) => void;
  onFocus?: (event: any) => void;
  popoverProps?: Partial<IPopoverProps>;
  roleStructure?: "menuitem" | "listoption" | "listitem" | "none"; // default: "menuitem"
  selected?: boolean;
  shouldDismissPopover?: boolean; // default: true
  submenuProps?: Partial<MenuProps>;
  tagName?: keyof JSX.IntrinsicElements | undefined; // default: "a"
  target?: string; // "_blank" to open in a new window
  textClassName?: string;
  /** madhive props */
  textSize?: `var(--font-size-${string})`;
  textSuffix?: ReactNode;
  iconSize?: IconSize;
  iconIntent?: Intent;
  iconWidth?: `var(--spacing-${string})` | "auto" | "0";
  children?: ReactNode;
  tabIndex?: number;
  to?: string; // navigate to path
}

const style = {
  menuItem: css`
    display: flex;
    align-items: center;
    line-height: 1.7;
    &:hover {
      background-color: var(--default-hover);
    }
    &:focus {
      background-color: var(--default-focus);
    }
  `,
  role: {
    menuitem: css`
      padding: var(--spacing-8) var(--spacing-16);
      min-height: 48px;
    `,
    listoption: css``,
    none: css``
  }
};

const MenuItem = forwardRef<BlueprintMenuItem, Props>(
  (
    { iconSize, iconIntent, elementRef, roleStructure = "menuitem", ...props },
    ref
  ) => {
    const navigate = useNavigate();

    let { iconWidth } = props;

    if (typeof iconWidth === "undefined") {
      if (!props.icon) {
        iconWidth = "0";
      } else {
        iconWidth =
          roleStructure === "listoption"
            ? "var(--spacing-18)"
            : "var(--spacing-40)";
      }
    }

    const fontSize =
      props.textSize || roleStructure === "listoption"
        ? "var(--font-size-14)"
        : "var(--font-size-16)";

    const icon =
      typeof props.icon === "string" ? (
        <Icon
          icon={props.icon}
          style={{
            width: iconWidth
          }}
          iconSize={iconSize || IconSize.LARGE}
          intent={iconIntent || props.intent}
        />
      ) : (
        props.icon
      );

    const onClick = useCallback(
      (e: any) => {
        if (props.to) {
          navigate(props.to);
        }
        if (typeof props.onClick === "function") {
          props.onClick(e);
        }
      },
      [props.to, props.onClick]
    );

    return (
      <BlueprintMenuItem
        ref={ref}
        className={`role-${roleStructure}`}
        css={[
          style.menuItem,
          css`
            font-size: ${fontSize};
          `,
          style.role[roleStructure]
        ]}
        {...props}
        onClick={onClick}
        tagName={props.tagName || "span"}
        icon={icon}
        labelElement={props.textSuffix}
      />
    );
  }
);

export default MenuItem;
