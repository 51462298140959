import { toLookerQuery } from "../../../models/looker/modules/query";
export class QueryHandler {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async create(body) {
        try {
            const queryBody = { ...body };
            delete queryBody.id;
            delete queryBody.client_id;
            return await this.sdk.looker.sdk.ok(this.sdk.looker.sdk.create_query(toLookerQuery(queryBody)));
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Runs a query using the Looker SDK. Makes 2 API calls— one to create the query, and another to run it.
     * @param body the query body to run.
     * @returns a promise that resolves with { data: any; embedHash: string } where:
     * - data from the query in JSON format
     * - the embed hash from the query's share URL. This can be used to embed the query as an explore or look in an iframe.
     *
     * NB: API calls to create and run queries via Looker SDK are not cached in memory by this function. When using this function in other Looker handlers, be sure to extend the Promise Handler class and wrap calls to this function in a cache promise if you want to cache the results.
     */
    async run(body) {
        try {
            const query = await this.create(body);
            if (!query.id) {
                throw new Error("No query id found");
            }
            const data = await this.sdk.looker.sdk.ok(this.sdk.looker.sdk.run_query({
                query_id: query.id,
                result_format: "json"
            }));
            const embedHash = this.parseEmbedHash(query);
            return {
                data,
                queryMeta: {
                    embedHash
                }
            };
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Hashes the query body to create a unique string key for caching. https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
     * @param query: the query body to hash.
     */
    async hashQuery(query) {
        return this.sdk.cryptography.crypto.subtle
            .digest("SHA-256", new TextEncoder().encode(JSON.stringify(query)))
            .then((buffer) => Array.from(new Uint8Array(buffer))
            .map((b) => b.toString(16).padStart(2, "0"))
            .join(""));
    }
    parseEmbedHash(query) {
        const { share_url: shareUrl } = query;
        if (!shareUrl) {
            throw new Error("No share URL found in query response");
        }
        // This regex performs a search for the string `/x/` followed by any additional characters
        // We do this to extract the query hash from the share URL generated by looker in the following format
        // `https://looker-instance-endpoint/x/query-hash`
        // The regex captures any value after the /x/ so in the example above the captured value would be `query-hash`
        const queryHashExtractor = /(?<=\/x\/).*/;
        const [embedHash] = new URL(shareUrl).pathname.match(queryHashExtractor) || [];
        if (!embedHash) {
            throw new Error("No embed hash found in query's share URL");
        }
        return embedHash;
    }
}
