export const PACING_QUERY = {
    view: "explore_pacing",
    fieldMap: {
        "vw_dim_lineitem.advertiser__name": "",
        "vw_dim_lineitem.advertiser__id": "",
        "vw_dim_lineitem.campaign__id": "",
        "vw_dim_lineitem.campaign__name": "",
        "vw_dim_lineitem.campaign_start_date": "",
        "vw_dim_lineitem.campaign_end_date": "",
        "vw_dim_lineitem.product__name": "",
        "vw_dim_lineitem.product__id": "",
        "vw_dim_lineitem.pacing_goal": "",
        "vw_dim_lineitem.lineitem_end_date": "",
        "vw_dim_lineitem.lineitem__id": "",
        "vw_dim_lineitem.lineitem__name": "",
        "vw_dim_lineitem.lineitem_start_date": "",
        "vw_dim_lineitem.lineitem_type": "",
        "vw_fct_impressions.pacing_status": "",
        "vw_fct_impressions.remaining_budget": "",
        "vw_fct_impressions.remaining_impressions": "",
        "vw_dim_lineitem.station__name": "",
        "vw_fct_impressions.total_impressions": "",
        "vw_fct_impressions.total_delivered": "",
        "vw_fct_impressions.total_spend": "",
        "vw_fct_impressions.delivered_goal": "",
        "vw_dim_lineitem.spend_goal": "",
        "vw_dim_lineitem.advertiser__iab_category__raw__name": "",
        "vw_dim_lineitem.advertiser__iab_category__raw__code": "",
        "vw_dim_creative_categories.raw__name": "",
        "vw_dim_creative_categories.raw__code": ""
    },
    pivots: null,
    fill_fields: null,
    filters: {
        "vw_dim_lineitem.campaign__status_name": "READY,PAUSED,CANCELLED",
        "vw_dim_lineitem.lineitem__status_name": "READY,PAUSED,CANCELLED",
        "vw_dim_lineitem.flt_analysis_interval": "7 day"
    },
    filter_expression: null,
    sorts: ["vw_dim_lineitem.campaign__name"],
    limit: "5000",
    column_limit: "50",
    total: null,
    row_total: null,
    subtotals: null,
    visible_ui_sections: null,
    has_table_calculations: false,
    model: "client_analytics",
    dynamic_fields: "[]",
    query_timezone: null,
    can: {
        cost_estimate: true,
        create: true,
        index: true,
        show: true,
        download: true,
        download_unlimited: true,
        edit_custom_fields: false,
        edit_table_calculations: false,
        explore: false,
        generate_drill_links: true,
        render: true,
        run: true,
        see_results: true,
        save: true,
        schedule: true,
        see_aggregate_table_lookml: false,
        see_derived_table_lookml: false,
        see_lookml: false,
        see_sql: false,
        use_custom_fields: true
    }
};
