import { EmbedClient as LookerEmbedClient } from "@looker/embed-sdk/lib/embed";
import axios from "axios";
/**
 * This is an override of Looker's embedding client.
 * In short, if you give the Looker embed SDK a method or
 * a request config to regenerate its api and nav tokens, it breaks: it never supplies the old tokens necessary for a proper refresh request.
 * See [this issue](https://github.com/looker-open-source/embed-sdk/issues/147).
 * To fix this, we override the method with the broken logic:
 *  - generateTokens
 *
 * tl;dr: Looker's exported implementations of this does not respect its config. Use this.
 */
export class EmbedClient extends LookerEmbedClient {
    constructor(builder) {
        super(builder);
        this.builder = builder;
        /**
         * We override this method to use our configured Axios instance, this allows for proper authenticated
         * requests to the BE and stears away from the use of old XMLHttpRequest usage in Looker's SDK.
         * This follows the original logic in [Looker's SDK](https://github.com/looker-open-source/embed-sdk/blob/56ff707296e7003e11060e332fd0646a40b5550e/src/embed.ts#L217)
         * @returns the URL to embed the dashboard.
         */
        this.getEmbedUrl = async () => {
            const src = this.builder.embedUrl;
            const auth = this.builder.auth;
            if (!auth?.url)
                return `${this.builder.apiHost}${src}`;
            let url = `${auth.url}?src=${encodeURIComponent(src)}`;
            if (auth.params) {
                for (const param of auth.params) {
                    url += `&${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`;
                }
            }
            const response = await axios.get(url, {
                withCredentials: auth.withCredentials
            });
            return response.data.url;
        };
        // because Looker marked this as private in their implementation, we have to override it in this way
        this["createUrl"] = this.getEmbedUrl;
    }
}
