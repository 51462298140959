import {
  Campaign,
  KnownOrganizationIds,
  CampaignStatus,
  CreativeFlight,
  DayOfTheWeek,
  LineItem,
  ServiceStatus,
  daypartToServiceDaypart,
  isLiveStatus,
  metadataToExternalMetadata,
  MediaType,
  User,
  CampaignAuditColumns,
  State,
  LineItemBase
} from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";
import {
  dateFullDisplayWithTime,
  resetTimeIfChangingDay,
  setDateToMidnight,
  setDateToBeforeMidnight
} from "lib/utils/date";
import calculateUserShouldBeAbleToSetInstructionLive from "api/campaigns/calculateUserShouldBeAbleToSetInstructionLive";
import { DEFAULT_CAMPAIGN_METADATA_FIELDS } from "api/constants";
import {
  Daypart,
  DraftLineItem,
  LineItemCreativeMaybeWithoutId,
  PossibleQuickEditStatuses,
  PremionCampaignExternalMetadata,
  PremionLineItemExternalMetadata,
  SalesforceCampaignExternalMetadata,
  ScrippsCampaignExternalMedatata,
  ScrippsLineItemExternalMetadata,
  ShallowLineItem
} from "campaign-types";
import { RawInstructionStatus } from "features/ManageCampaigns/constants";
import { LIST_OF_STATES_WITH_ABBREVIATIONS_MAP } from "features/ManageCampaigns/LineItemScreen/constants";
import { ColumnNamesForCSV } from "features/ManageCampaigns/MainScreen/constants";
import { displayIds } from "features/ManageCampaigns/utils";
import { formatAsUsd } from "lib/utils/currency";
import { LineItemWithFormattedMetadataFields } from "./types";
import {
  CAMPAIGN_AUDIT_COLUMN_HEADERS,
  CAMPAIGN_AUDIT_COLUMN_IDS
} from "./constants";
import { isPacingToImpressions } from "./lineitems/utils/targeting";

/**
 * Returns true if the provided campaign or line item was created via Adbook (an OMS).
 */
export const isAdbookOrder = (lineItemOrCampaign: LineItem | Campaign) => {
  const { adbookCampaignId, adbookWideOrbitId } =
    lineItemOrCampaign?.meta || {};

  return !!adbookCampaignId || !!adbookWideOrbitId;
};

export const getOmsLabel = (): string => {
  const user = madSDK.getCurrentUser();

  if (!user) {
    return "OMS";
  }
  return user.settings.extMeta.omsLabel;
};

export const getExternalIdLabel = (
  type: "campaign" | "lineItem" = "campaign"
): string => {
  const user = madSDK.getCurrentUser();
  const externalIdLabelDefault = "External ID";

  let externalIdLabelLookup: Record<string, string> = {
    [KnownOrganizationIds.TOWNSQUARE]: "External CPM ID",
    [KnownOrganizationIds.UNIVISION]: "Campaign External ID",
    [KnownOrganizationIds.BAHAKEL]: "Campaign External ID",
    [KnownOrganizationIds.PREMION]: "Freewheel IO ID"
  };

  if (type === "lineItem") {
    externalIdLabelLookup = {
      [KnownOrganizationIds.UNIVISION]: "Line External ID"
    };
  }

  return (
    externalIdLabelLookup[user?.primaryOrganizationId || ""] ||
    externalIdLabelDefault
  );
};

/**
 * Users should only be able to add line items to campaigns if editable and end dates are some time in the future.
 * This is due to the campaign shell acting as a guardrail for any of it's descendent instructions. User should not
 * be able to create a line item with dates that escape the bounds of its parent campaign.
 */
export const isUserAbleToAddDescendantToParentCampaignNonLegacy = (
  campaign: Campaign,
  canManage: boolean
) => canManage && campaign?.isEditable && campaign.endDate! > new Date();

/**
 * Converts a ServiceStatus to a RawInstructionStatus
 * @param serviceStatus: a ServiceStatus
 * @returns a RawInstructionStatus
 */
export const convertServiceStatusToRawInstructionStatus = (
  serviceStatus: ServiceStatus
): RawInstructionStatus => {
  switch (serviceStatus) {
    case ServiceStatus.DRAFT:
      return RawInstructionStatus.DRAFT;
    case ServiceStatus.READY:
      return RawInstructionStatus.DELIVERABLE;
    case ServiceStatus.PAUSED:
      return RawInstructionStatus.PAUSED;
    case ServiceStatus.CANCELLED:
      return RawInstructionStatus.CANCELED;
    case ServiceStatus.ARCHIVED:
      return RawInstructionStatus.ARCHIVED;
    default:
      return ServiceStatus.INVALID as unknown as RawInstructionStatus;
  }
};

/**
 *
 * @param externalMetadata metadata fields on the lineitem that were received from the backend service
 * @returns formatted metadata fields that conform with the ShallowLineItem type
 */
export const getFormattedMetadataFields = (
  externalMetadata:
    | Partial<PremionLineItemExternalMetadata & ScrippsLineItemExternalMetadata>
    | null
    | undefined
) => {
  const initialMetadataFields: Partial<
    PremionCampaignExternalMetadata &
      ScrippsCampaignExternalMedatata &
      SalesforceCampaignExternalMetadata
  > = {
    ...DEFAULT_CAMPAIGN_METADATA_FIELDS,
    ...externalMetadata
  };

  const {
    adbook_status: scrippsFieldAdbookStatus,
    adbook_market: scrippsFieldAdbookMarket,
    adbook_client_name: scrippsFieldAdbookClient,
    adbook_wide_orbit_id: scrippsWideOrbitId,
    adbook_package_id: scrippsFieldAdbookPackageId,
    adbook_server_mt_alias: scrippsFieldAdbookPackageName,
    adbook_adbook_id: scrippsFieldAdbookId,
    adbook_segment_notes: scrippsFieldAdbookSegmentNotes,
    adbook_geo: scrippsFieldAdbookGeo,
    adbook_geo_ids: scrippsFieldAdbookGeoIds,
    adbook_agency_id: scrippsFieldAdbookAgencyId,
    adbook_external_advertiser_id: scrippsFieldAdbookExternalAdvertiserId,
    adbook_campaign_id: scrippsFieldAdbookCampaignId,
    adbook_advertiser: scrippsFieldAdbookAdvertiser,
    adbook_station: scrippsFieldAdbookStation,
    adbook_client_name: scrippsFieldAdbookClientName,
    adbook_drop_id: scrippsFieldAdbookDropId,
    adbook_station_id: scrippsFieldAdbookStationId,
    adbook_line_item_status: scrippsFieldAdbookLineItemStatus,
    adbook_scripps_advertiser_id: scrippsFieldAdbookScrippsId,
    adbook_advertiser_id: scrippsFieldAdbookAdvertiserId,

    adbook_price_type: scrippsFieldAdbookPriceType,
    adbook_price: scrippsFieldAdbookPrice,
    adbook_gross_price: scrippsFieldAdbookGrossPrice,
    adbook_demo_audience: scrippsFieldAdbookStnDemoAudience,
    adbook_consumer_audience: scrippsFieldAdbookStnConsumerAudience,
    adbook_custom_segment: scrippsFieldAdbookCustomSegment,
    adbook_custom_segment_notes: scrippsFieldAdbookCustomSegmentNotes,
    placements_io_id: premionPlacementsIoId,
    placements_io_group_id: premionPlacementsIoGroupId,
    postal_codes: scrippsFieldAdbookPostalCodes,
    adbook_revision: scrippsFieldAdbookRevision,
    adbook_last_updated: scrippsFieldAdbookLastUpdated,
    adbook_agency_name: scrippsFieldAdbookAgencyName,
    adbook_package_position_path: scrippsFieldAdbookPackagePositionPath,
    adbook_position_name: scrippsFieldAdbookPositionName,
    adbook_advertiser_domain: scrippsFieldAdbookAdvertiserDomain,
    adbook_package_level: scrippsFieldAdbookPackageLevel,
    adbook_last_changed_by: scrippsFieldAdbookLastChangedBy,
    adbook_advertiser_whitelist: scrippsFieldAdbookAdvertiserWhitelist,
    adbook_whitelist_name: scrippsFieldAdbookAdvertiserWhitelistName,
    adbook_product: scrippsFieldAdbookProduct,
    adbook_contract_date: scrippsFieldAdbookContractDate,
    double_verify: scrippsIsDoubleVerify,
    rfp_name: premionFieldRfpName,

    salesforce_insertion_order_id: salesforceInsertionOrderId
  } = initialMetadataFields;

  const transformedMetadataFields = {
    scrippsIsDoubleVerify: !!(
      scrippsIsDoubleVerify && scrippsIsDoubleVerify === "true"
    ),
    premionFieldRfpName: premionFieldRfpName
      ? String(premionFieldRfpName)
      : undefined,
    premionPlacementsIoId: premionPlacementsIoId
      ? premionPlacementsIoId.toString()
      : undefined,
    premionPlacementsIoGroupId: premionPlacementsIoGroupId?.toString(),
    scrippsFieldAdbookClient: scrippsFieldAdbookClient
      ? scrippsFieldAdbookClient.toString()
      : undefined,
    scrippsFieldAdbookMarket: scrippsFieldAdbookMarket
      ? scrippsFieldAdbookMarket.toString()
      : undefined,
    scrippsFieldAdbookStatus,
    scrippsWideOrbitId: scrippsWideOrbitId
      ? scrippsWideOrbitId.toString()
      : undefined,

    scrippsFieldAdbookPackageId: scrippsFieldAdbookPackageId
      ? scrippsFieldAdbookPackageId.toString()
      : undefined,
    scrippsFieldAdbookPackageName: scrippsFieldAdbookPackageName
      ? scrippsFieldAdbookPackageName.toString()
      : undefined,
    scrippsFieldAdbookId: scrippsFieldAdbookId
      ? scrippsFieldAdbookId.toString()
      : undefined,

    scrippsFieldAdbookSegmentNotes: scrippsFieldAdbookSegmentNotes
      ? scrippsFieldAdbookSegmentNotes.toString()
      : undefined,

    scrippsFieldAdbookGeo: scrippsFieldAdbookGeo
      ? scrippsFieldAdbookGeo.toString()
      : undefined,
    scrippsFieldAdbookGeoIds: scrippsFieldAdbookGeoIds
      ? scrippsFieldAdbookGeoIds.toString()
      : undefined,

    scrippsFieldAdbookAgencyId: scrippsFieldAdbookAgencyId
      ? scrippsFieldAdbookAgencyId.toString()
      : undefined,

    scrippsFieldAdbookExternalAdvertiserId:
      scrippsFieldAdbookExternalAdvertiserId
        ? scrippsFieldAdbookExternalAdvertiserId.toString()
        : undefined,

    scrippsFieldAdbookCampaignId: scrippsFieldAdbookCampaignId
      ? scrippsFieldAdbookCampaignId.toString()
      : undefined,

    scrippsFieldAdbookAdvertiser: scrippsFieldAdbookAdvertiser
      ? scrippsFieldAdbookAdvertiser.toString()
      : undefined,
    scrippsFieldAdbookStation: scrippsFieldAdbookStation
      ? scrippsFieldAdbookStation.toString()
      : undefined,

    scrippsFieldAdbookClientName: scrippsFieldAdbookClientName
      ? scrippsFieldAdbookClientName.toString()
      : undefined,

    scrippsFieldAdbookDropId: scrippsFieldAdbookDropId
      ? scrippsFieldAdbookDropId.toString()
      : undefined,
    scrippsFieldAdbookStationId: scrippsFieldAdbookStationId
      ? scrippsFieldAdbookStationId.toString()
      : undefined,

    scrippsFieldAdbookScrippsId: scrippsFieldAdbookScrippsId
      ? scrippsFieldAdbookScrippsId.toString()
      : undefined,

    scrippsFieldAdbookAdvertiserId: scrippsFieldAdbookAdvertiserId
      ? scrippsFieldAdbookAdvertiserId.toString()
      : undefined,
    scrippsFieldAdbookPriceType: scrippsFieldAdbookPriceType
      ? scrippsFieldAdbookPriceType.toString()
      : undefined,

    scrippsFieldAdbookPrice: scrippsFieldAdbookPrice
      ? scrippsFieldAdbookPrice.toString()
      : undefined,
    scrippsFieldAdbookGrossPrice: scrippsFieldAdbookGrossPrice
      ? scrippsFieldAdbookGrossPrice.toString()
      : undefined,

    scrippsFieldAdbookStnDemoAudience: scrippsFieldAdbookStnDemoAudience
      ? scrippsFieldAdbookStnDemoAudience.toString()
      : undefined,

    scrippsFieldAdbookStnConsumerAudience: scrippsFieldAdbookStnConsumerAudience
      ? scrippsFieldAdbookStnConsumerAudience.toString()
      : undefined,

    scrippsFieldAdbookCustomSegment: scrippsFieldAdbookCustomSegment
      ? scrippsFieldAdbookCustomSegment.toString()
      : undefined,

    scrippsFieldAdbookCustomSegmentNotes: scrippsFieldAdbookCustomSegmentNotes
      ? scrippsFieldAdbookCustomSegmentNotes.toString()
      : undefined,

    scrippsFieldAdbookPostalCodes: scrippsFieldAdbookPostalCodes
      ? scrippsFieldAdbookPostalCodes.toString()
      : undefined,

    scrippsFieldAdbookProduct: scrippsFieldAdbookProduct
      ? scrippsFieldAdbookProduct.toString()
      : undefined,

    scrippsFieldAdbookRevision: scrippsFieldAdbookRevision
      ? scrippsFieldAdbookRevision.toString()
      : undefined,

    scrippsFieldAdbookLastUpdated: scrippsFieldAdbookLastUpdated
      ? scrippsFieldAdbookLastUpdated.toString()
      : undefined,

    scrippsFieldAdbookLineItemStatus: scrippsFieldAdbookLineItemStatus
      ? scrippsFieldAdbookLineItemStatus.toString()
      : undefined,

    scrippsFieldAdbookAgencyName: scrippsFieldAdbookAgencyName
      ? scrippsFieldAdbookAgencyName.toString()
      : undefined,
    scrippsFieldAdbookPackagePositionPath: scrippsFieldAdbookPackagePositionPath
      ? scrippsFieldAdbookPackagePositionPath.toString()
      : undefined,

    scrippsFieldAdbookPositionName: scrippsFieldAdbookPositionName
      ? scrippsFieldAdbookPositionName.toString()
      : undefined,
    scrippsFieldAdbookAdvertiserDomain: scrippsFieldAdbookAdvertiserDomain
      ? scrippsFieldAdbookAdvertiserDomain.toString()
      : undefined,
    scrippsFieldAdbookPackageLevel: scrippsFieldAdbookPackageLevel
      ? scrippsFieldAdbookPackageLevel.toString()
      : undefined,
    scrippsFieldAdbookLastChangedBy: scrippsFieldAdbookLastChangedBy
      ? scrippsFieldAdbookLastChangedBy.toString()
      : undefined,

    scrippsFieldAdbookAdvertiserWhitelistName:
      scrippsFieldAdbookAdvertiserWhitelistName
        ? scrippsFieldAdbookAdvertiserWhitelistName.toString()
        : undefined,

    scrippsFieldAdbookAdvertiserWhitelist: scrippsFieldAdbookAdvertiserWhitelist
      ? scrippsFieldAdbookAdvertiserWhitelist.toString()
      : undefined,

    scrippsFieldAdbookContractDate: scrippsFieldAdbookContractDate
      ? scrippsFieldAdbookContractDate.toString()
      : undefined,

    salesforceInsertionOrderId: salesforceInsertionOrderId?.toString()
  };
  return transformedMetadataFields;
};

/**
 *
 * @param lineItem: a lineItem following the sdk's LineItem model
 * @returns a lineitem with formatted metadata fields that conform with the ShallowLineItem type
 */
export const lineItemToLineItemWithFormattedMetadataFields = (
  lineItem: LineItem
): LineItemWithFormattedMetadataFields => {
  const { meta } = lineItem;

  const lineItemWithMetadata: LineItemWithFormattedMetadataFields = {
    ...lineItem,
    isAdbookOrder: isAdbookOrder(lineItem),
    campaignId: lineItem.parent,
    campaignName: lineItem.parentName,
    ...getFormattedMetadataFields(metadataToExternalMetadata(meta))
  };
  return lineItemWithMetadata;
};

/**
 *
 * @param lineItem: a lineItem following the sdk's LineItem model
 * @returns a lineItem following the ShallowLineItem model
 */
export const lineItemToShallowLineItem = (
  lineItem: LineItem
): ShallowLineItem => {
  const {
    id,
    budget,
    bookedImpressions,
    archivedCreatives,
    attachedCreatives,
    creativesCount,
    dayparts,
    frequencies,
    endDate,
    startDate,
    startAsap,
    name,
    mediaType,
    productId,
    externalOrderManagementSystemId,
    externalId,
    instructionStatus,
    lastUpdated,
    version,
    geoTargeting,
    meta,
    status,
    criticalStatuses,
    parent,
    parentName,
    segments,
    deviceCaps,
    isEditable,
    updatedBy,
    publisherGroupId,
    scenarioId,
    iab_category_rtb_ids
  } = { ...lineItem };

  /** Convert the creatives to shallow format */
  const shallowArchivedCreatives = archivedCreatives.map(creative => {
    return {
      ...creative,
      startASAP: creative?.startAsap || false,
      status: convertServiceStatusToRawInstructionStatus(creative.status),
      attachedLineItemId: id
    } as LineItemCreativeMaybeWithoutId;
  });
  const shallowAttachedCreatives = attachedCreatives.map(creative => {
    return {
      ...creative,
      startASAP: creative?.startAsap || false,
      status: convertServiceStatusToRawInstructionStatus(creative.status),
      attachedLineItemId: id
    } as LineItemCreativeMaybeWithoutId;
  });

  /** Convert geoTargets to shallow format */
  const shallowGeoTargetingInclude = {
    country: geoTargeting.include.country,
    districts: geoTargeting.include.districts,
    dmaCodes: geoTargeting.include.dmas,
    states: geoTargeting.include.regions,
    zipCodes: geoTargeting.include.postalCodes
  };
  const shallowGeoTargetingExclude = {
    country: geoTargeting.exclude.country,
    districts: geoTargeting.exclude.districts,
    dmaCodes: geoTargeting.exclude.dmas,
    states: geoTargeting.exclude.regions,
    zipCodes: geoTargeting.exclude.postalCodes
  };

  const shallowLineItem: ShallowLineItem = {
    isAdbookOrder: isAdbookOrder(lineItem),
    id: id as string,
    budget: budget!,
    bookedImpressions: bookedImpressions || 0,
    appliedSegmentIds: segments || [],
    archivedCreatives: shallowArchivedCreatives,
    attachedCreatives: shallowAttachedCreatives,
    creativesCount,
    dayparts: daypartToServiceDaypart(dayparts),
    frequencies: frequencies || [],
    endDate,
    startDate,
    startASAP: startAsap || false,
    name,
    parent,
    parentName,
    product: productId,
    orderManagementSystemId: externalOrderManagementSystemId,
    externalId,
    parentCampaignId: lineItem.parent,
    campaignName: lineItem.parentName,
    rawStatus: instructionStatus,
    lastUpdated: lastUpdated!,
    updatedBy: updatedBy!,
    hasFrequencyCap: true, // Assume every line item has a frequency cap.
    version,
    mediaType,
    geoTargeting: {
      exclude: shallowGeoTargetingExclude,
      include: shallowGeoTargetingInclude
    },
    deviceCaps,
    whitelistId: publisherGroupId,
    iab_category_rtb_ids,
    status,
    isUserEditable: isEditable,
    userCanSetLive: calculateUserShouldBeAbleToSetInstructionLive(status),
    criticalStatuses,
    scenarioId,
    isUserEditableIgnoreArchived:
      status === CampaignStatus.ARCHIVED || isEditable,

    /// Metadata fields
    ...getFormattedMetadataFields(metadataToExternalMetadata(meta))
  };
  return shallowLineItem;
};

/**
 * Converts a RawInstructionStatus to a ServiceStatus
 * @param rawInstructionStatus: a RawInstructionStatus
 * @returns a ServiceStatus
 */
export const convertRawInstructionStatusToServiceStatus = (
  rawInstructionStatus: RawInstructionStatus | PossibleQuickEditStatuses
): ServiceStatus => {
  switch (rawInstructionStatus) {
    case RawInstructionStatus.DRAFT:
      return ServiceStatus.DRAFT;
    case RawInstructionStatus.DELIVERABLE:
      return ServiceStatus.READY;
    case RawInstructionStatus.PAUSED:
      return ServiceStatus.PAUSED;
    case RawInstructionStatus.CANCELED:
      return ServiceStatus.CANCELLED;
    case RawInstructionStatus.ARCHIVED:
      return ServiceStatus.ARCHIVED;
    default:
      return ServiceStatus.INVALID;
  }
};

/**
 * Covert a daypart to the expected Day part for the service
 * @param serviceDayparts Service dayparts based on operator and dayparts given
 * @returns dayparts Record of exclude day parts
 */
export const serviceDaypartToDaypart = (
  serviceDayparts: Daypart[]
): Record<DayOfTheWeek, number[]> => {
  const dayparts = {} as Record<DayOfTheWeek, number[]>;

  serviceDayparts.forEach(daypart => {
    dayparts[daypart.day] = daypart.hours;
  });

  return dayparts;
};

/**
 * Converts a draft line item to a line item.
 * Note that this is only needed until the campaign line items table is refactored to use the sdk LineItem model.
 * @param draftLineItem: a lineItem following the smithers DraftLineItem model
 * @returns a lineItem following the sdk LineItem model
 */
export const draftLineItemToLineItem = (
  campaignId: string,
  d: DraftLineItem
): LineItem => ({
  archivedCreatives: [],
  attachedCreatives:
    d.attachedCreatives?.map(creative => {
      return {
        ...creative,
        startAsap: creative.startASAP,
        status: convertRawInstructionStatusToServiceStatus(creative.status),
        attachedLineItemId: ""
      } as CreativeFlight;
    }) || [],
  bookedImpressions: d.bookedImpressions,
  budget: d.budget,
  creativesCount: 0,
  criticalStatuses: [],
  dayparts: serviceDaypartToDaypart(d.dayparts),
  deviceCaps: d.deviceType,
  endDate: d.endDate,
  externalId: d.externalId,
  externalOrderManagementSystemId: "",
  frequencies: d.frequencies,
  geoTargeting: {
    include: {
      country: d.geoTargeting.include.country,
      districts: d.geoTargeting.include.districts,
      dmas: d.geoTargeting.include.dmaCodes,
      regions: d.geoTargeting.include.states,
      postalCodes: d.geoTargeting.include.zipCodes
    },

    exclude: {
      country: d.geoTargeting.exclude.country,
      districts: d.geoTargeting.exclude.districts,
      dmas: d.geoTargeting.exclude.dmaCodes,
      regions: d.geoTargeting.exclude.states,
      postalCodes: d.geoTargeting.exclude.zipCodes
    }
  },
  goalInterval: d.goalInterval,
  id: "",
  effectiveCpm: d.effectiveCpm,
  instructionStatus: ServiceStatus.DRAFT,
  isEditable: true,
  isLive: () => isLiveStatus(CampaignStatus.MISSING_CREATIVE),
  name: d.name,
  parent: campaignId,
  parentName: "",
  productId: d.productId || "",
  publisherGroupId: d.whitelistId,
  segments: d.appliedSegmentIds,
  startAsap: d.startASAP,
  startDate: d.startDate,
  status: CampaignStatus.MISSING_CREATIVE,
  version: 0
});

/**
 * Looks up a dma name from dma code to dma name map as well as a state name
 * from state abbreviation to state name
 */
export const displayGeoNonLegacy = (
  dmasMap: Map<string, string>,
  lineItem: LineItemWithFormattedMetadataFields
) => {
  // get dma name
  const lineItmeWithGeo = lineItem.geoTargeting.include.dmas
    .map((dma: number) =>
      dmasMap.has(dma.toString()) ? dmasMap.get(dma.toString()) : ""
    )
    .filter(Boolean);

  // get state name
  const states = lineItem.geoTargeting.include.regions
    .map((state: string) =>
      LIST_OF_STATES_WITH_ABBREVIATIONS_MAP[state]
        ? LIST_OF_STATES_WITH_ABBREVIATIONS_MAP[state]
        : ""
    )
    .filter(Boolean);

  return lineItmeWithGeo.concat(states).join(", ");
};

/**
 *
 * Formats zip codes, dmas and states (regions) for csv. These properties are arrays and csv requires values to be strings
 */
export const getColumnValueNonLegacy = (
  formattedColumnName: string,
  dmasMap: Map<string, string>,
  lineItem: LineItemWithFormattedMetadataFields
) => {
  switch (formattedColumnName) {
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_POSTAL_CODES:
      return lineItem.geoTargeting.include.postalCodes.join(",");
    case ColumnNamesForCSV.GOAL:
      return isPacingToImpressions(lineItem)
        ? lineItem.bookedImpressions
        : formatAsUsd(lineItem?.budget || 0);
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_GEO_IDS:
      return displayIds(lineItem.scrippsFieldAdbookGeoIds);
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_GEO:
      return displayGeoNonLegacy(dmasMap, lineItem);
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_CUSTOM_SEGMENT:
      return !!lineItem.scrippsFieldAdbookCustomSegment;
    case ColumnNamesForCSV.EXTERNAL_ID:
      return lineItem.isAdbookOrder
        ? lineItem.scrippsFieldAdbookExternalAdvertiserId
        : lineItem.externalId;

    case ColumnNamesForCSV.SCRIPPS_ADBOOK_WHITE_LIST:
      return !!lineItem.scrippsFieldAdbookAdvertiserWhitelistName;
    case ColumnNamesForCSV.MEDIA_TYPE:
      return (lineItem.mediaType && MediaType[lineItem.mediaType]) || undefined;
    case ColumnNamesForCSV.CATEGORY:
      return lineItem.iab_category_rtb_ids?.length ?? 0;
    default:
      return lineItem[formattedColumnName] || "";
  }
};

export const deriveOMSIdToFieldName = (user: User | undefined): string => {
  return user?.settings.extMeta.omsIdFieldName ?? "OMS ID";
};

export const campaignsToAuditCSVData = (campaigns: Campaign[]) => {
  return campaigns.map(campaign => {
    return CAMPAIGN_AUDIT_COLUMN_HEADERS.map(column => {
      let data = campaign[CAMPAIGN_AUDIT_COLUMN_IDS[column]];

      switch (column) {
        case CampaignAuditColumns.UPDATED:
        case CampaignAuditColumns.START_DATE:
        case CampaignAuditColumns.END_DATE:
          data = dateFullDisplayWithTime(data);
          break;
        case CampaignAuditColumns.LINE_ITEMS:
          data = data.map((li: LineItem) => li.id)?.join(", ") || "";
          break;
        case CampaignAuditColumns.EXT_METAS:
          data =
            !data || Object.values(data).every(val => !val && val !== false)
              ? ""
              : JSON.stringify(data);
          break;
        case CampaignAuditColumns.STATUS:
          data = State[ServiceStatus[data]];
          break;
        case CampaignAuditColumns.CATEGORIES:
          data = data || "";
          break;
        default:
          break;
      }

      return data;
    });
  });
};

/**
 * Converts a raw instruction status to a campaign status.
 * @param status A raw instruction status.
 * @returns The relevant campaign status.
 */
export const convertRawInstructionStatusToCampaignStatus = (
  status: RawInstructionStatus
) => {
  const campaignStatusLookup: Record<RawInstructionStatus, CampaignStatus> = {
    [RawInstructionStatus.DRAFT]: CampaignStatus.READY,
    [RawInstructionStatus.DELIVERABLE]: CampaignStatus.LIVE,
    [RawInstructionStatus.PAUSED]: CampaignStatus.PAUSED,
    [RawInstructionStatus.CANCELED]: CampaignStatus.CANCELLED,
    [RawInstructionStatus.ARCHIVED]: CampaignStatus.ARCHIVED
  };
  return campaignStatusLookup[status];
};

/**
 * Used to derive start date for a creative about to be attached on line item.
 */
export const getAppropriateStartDate = (
  foundCreative: CreativeFlight | undefined,
  lineItem: LineItem | LineItemBase
) => {
  // If not live, check to see if this creative already exists within line item and belongs to line item so that user defined start date can be set on creative being attached
  if (foundCreative) {
    return foundCreative.startDate;
  }
  // If line item is live, return current start date
  if (lineItem.status === CampaignStatus.LIVE) {
    // Need to strip milliseconds
    const datePlusDayWithoutMilliseconds = new Date().setMilliseconds(0);
    return new Date(datePlusDayWithoutMilliseconds);
  }
  // Default to line item's start date if all else fails

  return lineItem.startDate;
};

/**
 * For campaign and line item start dates, the time should be reset to midnight if the day is changing.
 * @param oldDate: The previously-selected date.
 * @param newDate The newly-selected date.
 * @returns A copy of the new date, with the time reset if the day is being changed.
 */
export const resetTimeIfChangingStartDateDay = (
  oldDate: Date | undefined,
  newDate: Date | undefined
) => {
  if (!newDate) {
    return undefined;
  }

  const newDateWithTimeReset = setDateToMidnight(newDate);

  return resetTimeIfChangingDay(oldDate, newDate, newDateWithTimeReset);
};

/**
 * For campaign and line item end dates, the time should be reset to a second before midnight if the day is changing.
 * @param oldDate: The previously-selected date.
 * @param newDate The newly-selected date.
 * @returns A copy of the new date, with the time reset if the day is being changed.
 */
export const resetTimeIfChangingEndDateDay = (
  oldDate: Date | undefined,
  newDate: Date | undefined
) => {
  if (!newDate) {
    return undefined;
  }
  const newDateWithTimeReset = setDateToBeforeMidnight(newDate);

  return resetTimeIfChangingDay(oldDate, newDate, newDateWithTimeReset);
};
