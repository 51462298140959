import { ClientDeviceNames, RemoteConfigProperties, InventoryVideoType, InventoryMediaType } from "../../types";
import { satoshisToDollars } from "../../utils";
import { ProductDisplayType } from "../../models/product";
import { ChargeType } from "../../models/supplyCosts";
const productSelectionText = "A product type must be selected.";
const productTypeText = "A product type must be valid.";
export function validators(sdk) {
    return {
        name: (product, value) => {
            if (!value?.trim()?.length) {
                return "Name is required";
            }
            return undefined;
        },
        ecpm: (product, value) => {
            const dollarValue = value ? satoshisToDollars(value) : 0;
            if (dollarValue && dollarValue >= 1 && dollarValue <= 100) {
                return undefined;
            }
            return "must be between $1.00 and $100.00";
        },
        deviceCaps: (product, value) => {
            const values = Object.values(value || {});
            const deviceCapsTotalPercent = values.reduce((acc, val) => acc + Number(val), 0) / 100;
            if (deviceCapsTotalPercent < 100) {
                return "The sum of device caps must reach at least 100.00%.";
            }
            let error;
            for (const device of [
                ClientDeviceNames.DESKTOP,
                ClientDeviceNames.MOBILE,
                ClientDeviceNames.TABLET,
                ClientDeviceNames.CTV
            ]) {
                if (!value?.[device] && value?.[device] !== 0) {
                    error = "All device caps must be set to at least 0.00%.";
                    break;
                }
                if (value > 100 * 100) {
                    error = "No device cap can exceed 100.00%.";
                    break;
                }
            }
            return error;
        },
        dayparts: (product, value) => {
            if (!value?.length) {
                return undefined;
            }
            const hours = value.map((daypart) => daypart.hours).flat();
            if (hours.length === 7 * 24) {
                return "Cannot exclude all dayparts or include no dayparts.";
            }
            return undefined;
        },
        frequencies: (product, value) => {
            if (!value?.length) {
                return undefined;
            }
            const frequencyMap = new Map();
            const duplicateTimeUnits = new Set();
            value.forEach((frequency) => {
                const timeUnit = frequency?.duration?.time_unit || undefined;
                if (frequencyMap.has(timeUnit)) {
                    duplicateTimeUnits.add(timeUnit);
                    return;
                }
                frequencyMap.set(frequency.duration.time_unit, Number(frequency.max));
            });
            if (duplicateTimeUnits.size > 0) {
                return `Frequencies cannot have duplicate time units: ${[
                    ...duplicateTimeUnits.values()
                ].join(", ")}.`;
            }
            if (frequencyMap.size === 0) {
                return undefined;
            }
            const hourCap = Number(frequencyMap.get("HOUR") || 0);
            const dayCap = Number(frequencyMap.get("DAY") || 0);
            const weekCap = Number(frequencyMap.get("WEEK") || 0);
            const monthCap = Number(frequencyMap.get("MONTH") || 0);
            if (dayCap > 20) {
                return "The daily frequency cap cannot be greater than 20 per day.";
            }
            if (weekCap > 140) {
                return "The weekly frequency cap cannot be greater than 140 per week.";
            }
            if (monthCap > 620) {
                return "The monthly frequency cap cannot be greater than 620 per month.";
            }
            // order of these checks is intentional for the sake of UX
            if (frequencyMap.has("DAY") && hourCap > dayCap) {
                return "The hourly frequency cap cannot exceed the daily frequency cap.";
            }
            if (frequencyMap.has("WEEK") && dayCap > weekCap) {
                return "The daily frequency cap cannot exceed the weekly frequency cap.";
            }
            if (frequencyMap.has("WEEK") && hourCap > weekCap) {
                return "The hourly frequency cap cannot exceed the weekly frequency cap.";
            }
            if (frequencyMap.has("MONTH") && weekCap > monthCap) {
                return "The weekly frequency cap cannot exceed the monthly frequency cap.";
            }
            if (frequencyMap.has("MONTH") && hourCap > monthCap) {
                return "The hourly frequency cap cannot exceed the monthly frequency cap.";
            }
            if (frequencyMap.has("MONTH") && dayCap > monthCap) {
                return "The daily frequency cap cannot exceed the monthly frequency cap.";
            }
            return undefined;
        },
        chargeType: (product, value) => {
            const isChargeTypeEnabled = sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.INTERNAL_PRODUCT_CHARGE_TYPE);
            if (!isChargeTypeEnabled) {
                return undefined;
            }
            const chargeType = value?.trim();
            if (!chargeType) {
                return "A charge type must be selected.";
            }
            if (!Object.values(ChargeType).includes(chargeType)) {
                return "A charge type must be valid.";
            }
            return undefined;
        },
        videoType: (product, value) => {
            const trimmedMediaType = product?.mediaType?.trim();
            if (trimmedMediaType !== InventoryMediaType.VIDEO) {
                return undefined;
            }
            if (!value?.trim()) {
                return `${productSelectionText}`;
            }
            if (!Object.values(InventoryVideoType).includes(value?.trim())) {
                return `${productTypeText}`;
            }
            return undefined;
        },
        displayType: (product, value) => {
            const trimmedMediaType = product?.mediaType?.trim();
            if (trimmedMediaType !== InventoryMediaType.DISPLAY) {
                return undefined;
            }
            if (!value?.trim()) {
                return `${productSelectionText}`;
            }
            if (!Object.values(ProductDisplayType).includes(value?.trim())) {
                return `${productTypeText}`;
            }
            return undefined;
        }
    };
}
