import { FilterTypes, FilterValueTypes, parseIdFilter, parseInclusionFilter, validateFilterStructure } from "../../handlers/filter";
import axios from "axios";
import { Handler } from "../../handlers";
import { serviceSegmentToSegment } from "../../../models/dataManagementPlatform/segment";
import { MadSDKAbortError, NotImplementedError, UnauthenticatedError } from "../../../errors";
import { DmpSegmentsFetchFailed, DmpSegmentsUpsertFailed } from "./errors";
class Segments extends Handler {
    constructor(sdk) {
        super(sdk, "segments", { atomize: true });
        this.toDmpSegment = (dmp) => {
            return serviceSegmentToSegment(dmp);
        };
        /**
         * @param error: the error to whittle down into something more specific/segment-or-sdk-related.
         * @return: an appropriate Error if a specific error can be determined.
         */
        this.getSpecificSegmentError = (error) => {
            if (axios.isCancel(error)) {
                return new MadSDKAbortError();
            }
            return new DmpSegmentsFetchFailed(error);
        };
        /**
         * @param error: the error to whittle down into something more specific/segment-or-sdk-related.
         * @return: an appropriate Error if a specific error can be determined.
         */
        this.upsertSegmentError = (error) => {
            if (axios.isCancel(error)) {
                return new MadSDKAbortError();
            }
            return new DmpSegmentsUpsertFailed(error);
        };
    }
    async findItems(filters) {
        validateFilterStructure(filters, [
            { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT },
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING },
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.NUMBER }
        ]);
        const idsFromFilter = parseIdFilter(filters);
        const statusFromFilter = parseInclusionFilter(filters, "status", ["number"]);
        const includeLineItemsCount = filters?.fields?.includes("lineItemsCount");
        const formattedFilters = [];
        if (includeLineItemsCount) {
            formattedFilters.push(`withlineitemscount=true`);
        }
        if (idsFromFilter.size > 0) {
            formattedFilters.push(`id=${[...idsFromFilter].join(",")}`);
        }
        if (statusFromFilter.size > 0) {
            formattedFilters.push(`status=${[...statusFromFilter].join(",")}`);
        }
        const url = `${this.sdk.urls.baseAPIUrl}/dmpsegments${formattedFilters.length ? `?${formattedFilters.join("&")}` : ""}`;
        return this.getSegments(url);
    }
    /**
     * @param url: endpoint to hit
     * @return: all segments
     */
    getSegments(url) {
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return res.data.map((segment) => this.toDmpSegment(segment));
        })
            .catch((error) => {
            throw this.getSpecificSegmentError(error);
        }));
    }
    async createRetargetingSegment(payload) {
        // black box magic to generate a valid key since backend requires it for now
        async function newKey() {
            async function base64Arraybuffer(data) {
                const base64url = await new Promise((r) => {
                    const reader = new FileReader();
                    reader.onload = () => r(reader.result);
                    reader.readAsDataURL(new Blob([data]));
                });
                return base64url.split(",", 2)[1];
            }
            for (let i = 0; i < 100000; i++) {
                const key = crypto.getRandomValues(new Uint8Array(21));
                const encoded = await base64Arraybuffer(key);
                // eslint-disable-next-line no-continue
                if (/[+/]/.test(encoded))
                    continue;
                const digest = new Uint8Array(await crypto.subtle.digest("SHA-256", key));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                if (digest.at(-1) === 101)
                    return encoded;
            }
            return;
        }
        const url = `${this.sdk.urls.baseAPIUrl}/segment`;
        const id = await newKey();
        const finalPayload = {
            id,
            external_id: id,
            name: payload.name,
            description: payload.description,
            parent: payload.parent,
            retargeting: {
                [payload.retargetingType]: payload.target
            },
            status: 200
        };
        const response = await axios.post(url, finalPayload, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        // need to refetch retargeting segment as creation call doesn't return all fields
        const retargetSegment = (await this.find_once({
            where: [{ field: "id", type: FilterTypes.EQ, value: response.data.data.id }]
        }));
        this.cache.set(retargetSegment.id, retargetSegment);
        return retargetSegment;
    }
    async updateRetargetingSegment(payload) {
        const url = `${this.sdk.urls.baseAPIUrl}/segment`;
        const finalPayload = {
            id: payload.id,
            name: payload.name,
            parent: payload.parent,
            description: payload.description,
            retargeting: {
                [payload.retargetingType]: payload.target
            },
            external_id: payload.extenalId,
            status: payload.status
        };
        const response = await axios.post(url, finalPayload, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        const retargetingSegment = this.toDmpSegment(response.data.data);
        this.cache.set(retargetingSegment.id, retargetingSegment);
        return retargetingSegment;
    }
    async updateFirstPartySegment(payload) {
        const url = `${this.sdk.urls.baseAPIUrl}/segment`;
        const finalPayload = {
            id: payload.id,
            name: payload.name,
            parent: payload.parent,
            description: payload.description,
            external_id: payload.extenalId,
            status: payload.status
        };
        return new Promise((resolve, reject) => {
            axios
                .post(url, finalPayload, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(async (res) => {
                const segment = this.toDmpSegment(res.data.data);
                this.cache.set(segment.id, segment);
                resolve(segment);
            })
                .catch((error) => {
                reject(this.upsertSegmentError(error));
            });
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async saveItem(dmp) {
        throw new NotImplementedError("save");
    }
    async updateSegmentStatus(id, status) {
        const segment = await this.find_once({
            where: [
                {
                    field: "id",
                    type: FilterTypes.EQ,
                    value: id
                },
                {
                    field: "status",
                    type: FilterTypes.IN,
                    value: [200, 500]
                }
            ]
        });
        const user = this.sdk.getCurrentUser();
        if (typeof user === "undefined") {
            throw new UnauthenticatedError();
        }
        if (!segment || (!("target" in segment) && segment.parent !== user.primaryOrganizationId)) {
            throw new Error("Only first party and retargeting segments can be updated");
        }
        const updatePayload = "retargetingType" in segment
            ? {
                id: segment.id,
                name: segment.label,
                parent: segment.parent,
                retargeting: {
                    [segment.retargetingType]: segment.target
                },
                external_id: segment.externalId,
                status
            }
            : {
                id: segment.id,
                name: segment.label,
                parent: segment.parent,
                external_id: segment.externalId,
                status
            };
        const response = await axios.post(`${this.sdk.urls.baseAPIUrl}/segment`, updatePayload, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        const updatedSegment = this.toDmpSegment(response.data.data);
        updatedSegment.createdBy = segment.createdBy;
        updatedSegment.createdOn = segment.createdOn;
        this.cache.set(updatedSegment.id, updatedSegment);
        return updatedSegment;
    }
    async deleteItem(id) {
        return this.updateSegmentStatus(id, 500);
    }
    async unarchiveItem(id) {
        return this.updateSegmentStatus(id, 200);
    }
}
export default Segments;
