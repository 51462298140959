/**
 * Each property corresponds to the actual href. In order to change the URL of
 * a view, edit it here.
 */
export enum Routes {
  NOT_FOUND = "/404",
  LOGIN = "/login",
  LOGIN_LOCAL = "/dev",
  DASHBOARD = "/dashboard",
  DASHBOARD_ORION = "/dashboard-orion",
  HOME = "/",
  USER_PROFILE = "/profile",
  MANAGE_USERS = "/manage-users",
  MANAGE_PUBLISHER_GROUPS = "/manage-publisher-groups",
  MANAGE_CAMPAIGNS = "/manage-campaigns",
  MANAGE_DEALS = "/manage-deals",
  MANAGE_DEAL_GROUPS = "/manage-deals/deal-groups",
  TRACKING_PIXELS = "/tracking-pixels",
  CREATIVE_LIBRARY = "/creative-library",
  PACING = "/pacing",
  MANAGE_ADVERTISERS = "/manage-advertisers",
  MANAGE_AGENCIES = "/manage-agencies",
  MANAGE_STATIONS = "/manage-stations",
  MANAGE_PRODUCTS = "/manage-products",
  DELIVERY_REPORT = "/delivery-report",
  ADBOOK_CAMPAIGNS = "/adbook-campaigns",
  MANAGE_AUDIENCES = "/audiences",
  AUDIENCE_DATA_PROVISIONING = "/audience-data-provisioning",
  CUSTOM_REPORTING = "/custom-reporting",
  CRYPTO_DASHBOARD = "/crypto-dashboard",
  OOH_PLANNING = "/ooh-planner",
  DATASTUDIO_REPORTS = "/datastudio-reports",
  MANAGE_PUBLISHERS = "/manage-publishers",
  WIDE_ORBIT_ERRORS = "/wide-orbit-errors",
  PLANNER = "/planner",
  ADVANCED_EXPORTS = "/advanced-exports",
  FOOT_TRAFFIC_ATTRIBUTION = "/foot-traffic-attribution",
  FIRST_PARTY_DATA_ONBOARDING = "/first-party-data-onboarding",
  ADMIN = "/admin",
  KIT = "/kit",
  FIVE_MIN_SEPARATION = "/five-min-separation",
  CONVERSION_DASHBOARD = "/conversion-dashboard",
  SPEND_DASHBOARD = "/spend-dashboard",
  BI_CONVERSION_DASHBOARD = "/bi-conversion-dashboard",
  REPORT_BUILDER = "/report-builder",
  REPORTS = "/bi-reports",
  PACING_BETA = "/pacing-beta",
  ATTRIBUTION = "/attribution",
  PLANNER_ADMIN_TOOL = "/planner-admin-tool"
}

// PK: These are internal views, meaning no client has access to them
// This is where we'll keep a list of internal views that will need to be feature flagged via remote config
// TODO: Programmatically pull internal views from remote config once we get all views on there (if toggled false for everyone, it's unreleased). Is there a way to distinguish feature vs. view in remote config?
export const INTERNAL_VIEWS = [
  { name: "Audiences", route: Routes.MANAGE_AUDIENCES },
  {
    name: "Audience Data Provisioning",
    route: Routes.AUDIENCE_DATA_PROVISIONING
  },
  { name: "Manage Products", route: Routes.MANAGE_PRODUCTS },
  { name: "Deals", route: Routes.MANAGE_DEALS },
  { name: "Custom Reporting", route: Routes.CUSTOM_REPORTING },
  { name: "Crypto Dashboard", route: Routes.CRYPTO_DASHBOARD },
  {
    name: "DataStudio Reports",
    route: Routes.DATASTUDIO_REPORTS
  },
  {
    name: "Advanced Exports",
    route: Routes.ADVANCED_EXPORTS
  },
  {
    name: "Foot Traffic Attribution",
    route: Routes.FOOT_TRAFFIC_ATTRIBUTION
  },
  { name: "5 Minute Separation", route: Routes.FIVE_MIN_SEPARATION },
  { name: "Conversion Dashboard (BETA)", route: Routes.CONVERSION_DASHBOARD },
  { name: "Spend Dashboard (BETA)", route: Routes.SPEND_DASHBOARD },
  // BI Conversion Dashboard is the new view that embeds the
  // Looker BI Tool Conversion Dashboard in our Madhive UI.
  // We use the "BI" for "Business Intelligence" prefix
  // to differentiate it from the legacy Conversion Dashboard (BETA)
  // views whose route is shown above.
  {
    name: "BI Conversion Dashboard (ALPHA)",
    route: Routes.BI_CONVERSION_DASHBOARD
  },
  { name: "Report Builder (ALPHA)", route: Routes.REPORT_BUILDER },
  { name: "Reports (ALPHA)", route: Routes.REPORTS },
  {
    name: "Pacing (BETA)",
    route: Routes.PACING_BETA
  },
  {
    name: "Attribution",
    route: Routes.ATTRIBUTION
  },
  {
    name: "Planner Admin Tool",
    route: Routes.PLANNER_ADMIN_TOOL
  }
];

export enum AudienceDataProvisioningRoute {
  MAIN_SCREEN = "/",
  AUDIENCE_DATA_PROVISIONING_INDIVIDUAL_VIEW = "/:organizationId"
}

export enum CreativeLibraryRoute {
  ADD_CREATIVE = "create",
  EXISTING_CREATIVES = "saved-creatives",
  INDIVIDUAL_VIEW = "creatives/:creativeId",
  ADD_BULK_CREATIVE = "bulk-create"
}

export enum ManageCampaignsRoute {
  MAIN_SCREEN = "/",
  CREATE_CAMPAIGN = "campaigns/create",
  CAMPAIGN_INDIVIDUAL_VIEW = "campaigns/:campaignId",
  LINE_ITEM_INDIVIDUAL_VIEW = "lineItems/:lineItemId",
  CREATE_LINE_ITEM = "lineItems/create/:campaignId",
  CREATE_FLIGHT = "flights/create/:lineItemId",
  FLIGHT_INDIVIDUAL_VIEW = "flights/:flightId",
  UPLOAD_CAMPAIGN = "campaigns/upload"
}

export enum ManageProductsRoute {
  MAIN_SCREEN = "/",
  CREATE_PRODUCT = "products/create",
  PRODUCT_INDIVIDUAL_VIEW = "products/:productId"
}

export enum ManageDealsRoute {
  MAIN_SCREEN = "/",
  CREATE_SCREEN = "deal/create",
  DEAL_GROUPS_CREATE_SCREEN = "deal/deal-groups/create",
  DEAL_GROUPS_EXISTING_SCREEN = "deal-groups/:id",
  BULK_UPLOAD_SCREEN = "deal/upload",
  EXISTING_SCREEN = "deal/:id"
}

export enum LinearMeasurementRoute {
  MAIN_SCREEN = "/",
  REPORT_INDIVIDUAL_VIEW = "reports/:reportId",
  OTTREPORT_INDIVIDUAL_VIEW = "ottreports/:reportId"
}

export enum PremionPacingRoute {
  MAIN_SCREEN = "/"
}

export enum ManageAgenciesRoute {
  MAIN_SCREEN = "/"
}

export enum ManageAudiencesRoute {
  MAIN_SCREEN = "/",
  CREATE_AUDIENCE = "/audience/create",
  AUDIENCE_DETAIL_VIEW = "/audience/:audienceId",
  CREATE_RETARGETING_SEGMENT = "/retargeting-segment/create",
  RETARGETING_SEGMENT_DETAIL_VIEW = "/retargeting-segment/:retargetingSegmentId",
  SEGMENT_DETAIL_VIEW = "/segment/:segmentId"
}

export enum CustomReportingRoute {
  MAIN_SCREEN = "/",
  CREATE_REPORT = "report/create",
  REPORT_DETAIL_VIEW = "report/:reportId"
}

export enum OOHPlanningRoute {
  MAIN_SCREEN = "/",
  CREATE_PLAN = "plan/create",
  PLAN_INDIVIDUAL_VIEW = "plan/:planId",
  PLAN_SCENARIO_VIEW = "plan/:planId/scenario/:scenarioId"
}

export enum CrossPlatformPlannerRoute {
  MAIN_SCREEN = "/",
  CREATE_PLAN = "plan/create",
  PLAN_DETAIL_VIEW = "plan/:planId/*"
}

export enum DataStudioReportsRoute {
  MAIN_SCREEN = "/"
}

export enum ManageUsersRoute {
  MAIN_SCREEN = "/",
  CREATE_USER = "user/create",
  INDIVIDUAL_VIEW = "user/:userId"
}

export enum ManagePublisherGroupRoute {
  MAIN_SCREEN = "/",
  GROUPS_ADD = "add",
  GROUPS_VIEW = "view/:publisherGroupId",
  GROUPS_EDIT = "edit/:publisherGroupId"
}

export enum PlannerRoute {
  MAIN = "/",
  CREATION = "create",
  PLAN = "plan/:planId",
  ADD_SCENARIOS = "plan/:planId/add-scenarios",
  SCENARIO = "plan/:planId/scenario/:scenarioId"
}

export enum AdvancedExportsRoute {
  MAIN = "/",
  TEMPLATES_CREATE = "templates/create",
  TEMPLATES_EDIT = "templates/edit/:templateId",
  EXPORT = "export",
  EXPORT_FROM_TEMPLATE = "templates/export/:templateId",
  SCHEDULE_FROM_TEMPLATE = "templates/schedule/:templateId",
  SCHEDULE_EDIT = "templates/schedule/edit/:scheduleId"
}

export enum FootTrafficAttributionRoute {
  MAIN_SCREEN = "/",
  FOOT_TRAFFIC_REPORT_CREATE = "report/create",
  FOOT_TRAFFIC_REPORT_VIEW = "report/:reportId"
}

export enum AdminRoutes {
  HOME = "/",
  ORG_EDITOR = "organizations",
  UX_CONFIG = "ux"
}
