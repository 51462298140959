import { dealToService, serviceToDeal } from "./deals";
/**
 * Converts an array of `Deal` objects to an array of `ServiceDeal` objects.
 *
 * @param inventories - An array of grouped `Deal` objects representing the inventories.
 * @returns An array of `ServiceDeal` objects.
 */
export const inventoriesToServiceInventories = (inventories) => {
    return inventories?.map((deal) => dealToService(deal));
};
/**
 * Converts an array of ServiceDeal objects to an array of Deal objects.
 *
 * @param inventories - An array of grouped ServiceDeal objects to be converted.
 * @returns An array of Deal objects.
 */
export const serviceInventoriesToInventories = (inventories) => {
    return inventories?.map((deal) => serviceToDeal(deal));
};
/**
 * Enum representing different types of inventory media/video.
 * Shared bewteen Product and Deals models.
 * @enum {string}
 */
export var ServiceInventoryMediaType;
(function (ServiceInventoryMediaType) {
    ServiceInventoryMediaType["NONE"] = "NONE";
    ServiceInventoryMediaType["VIDEO"] = "VIDEO";
    ServiceInventoryMediaType["AUDIO"] = "AUDIO";
    ServiceInventoryMediaType["DISPLAY"] = "DISPLAY";
})(ServiceInventoryMediaType || (ServiceInventoryMediaType = {}));
export var InventoryMediaType;
(function (InventoryMediaType) {
    InventoryMediaType["VIDEO"] = "Video";
    InventoryMediaType["AUDIO"] = "Audio";
    InventoryMediaType["DISPLAY"] = "Display";
})(InventoryMediaType || (InventoryMediaType = {}));
export var ServiceInventoryVideoType;
(function (ServiceInventoryVideoType) {
    ServiceInventoryVideoType["VT_NONE"] = "VT_NONE";
    ServiceInventoryVideoType["OTT"] = "OTT";
    ServiceInventoryVideoType["OLV"] = "OLV";
    ServiceInventoryVideoType["OTV"] = "OTV";
    ServiceInventoryVideoType["DOOH"] = "DOOH";
})(ServiceInventoryVideoType || (ServiceInventoryVideoType = {}));
export var InventoryVideoType;
(function (InventoryVideoType) {
    InventoryVideoType["OTT"] = "OTT";
    InventoryVideoType["OLV"] = "OLV";
    InventoryVideoType["OTV"] = "OTV";
    InventoryVideoType["DOOH"] = "DOOH";
})(InventoryVideoType || (InventoryVideoType = {}));
