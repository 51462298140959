import { css } from "@emotion/react";

export const styles = {
  wrapper: css`
    display: flex;
    cursor: pointer;
    height: var(--spacing-16);
    min-width: var(--spacing-32);
  `,
  label: css`
    display: inline-block;
    margin-right: var(--spacing-8);
  `,
  inputWrapper: css`
    position: relative;
    height: var(--spacing-16);
  `,
  handle: css`
    position: absolute;
    top: 1px;
    left: 0;
    width: var(--spacing-32);
    height: var(--spacing-16);
    border-radius: var(--spacing-12);
    background: var(--color-tertiary);
    cursor: pointer;
    z-index: 1;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: var(--spacing-12);
      height: var(--spacing-12);
      position: relative;
      top: 2px;
      background: var(--white);
      transition: 0.2s;
      margin-left: 2px;
    }
    &:hover {
      background: var(--color-border-primary);
    }
    &:active {
      background: var(--color-border-primary);
    }
  `,
  input: css`
    position: absolute;
    opacity: 0;
    z-index: 2;
    border-radius: var(--spacing-16);
    width: var(--spacing-32);
    height: var(--spacing-16);
    cursor: pointer;
  `,
  checked: css`
    background: var(--color-success);
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: var(--spacing-12);
      height: var(--spacing-12);
      position: relative;
      top: 2px;
      margin-left: 18px;
      transition: 0.2s;
    }
    &:hover {
      background: var(--color-success);
    }
    &:active {
      background: var(--color-success);
    }
  `,
  disabled: css`
    cursor: default;
  `,
  disabledChecked: css`
    background: var(--color-background-success);
  `,
  disabledUnchecked: css`
    background: var(--color-background-tertiary);
  `
};
